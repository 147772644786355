/* TODO: This css file should be deprecated and implemented in another way */
.grecaptcha-badge {
  z-index: 20;
}

/* Rich Text UL styling  */
ul.ul-bare {
  padding: 0;
  list-style: none;
}
